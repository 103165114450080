import React from "react";
import Divider from "../divider";
import { Link } from "react-router-dom";
import "../../assets/styles/dashboard.css";
import Login from "./Login";
import Register from "./register";

const Dashboard = () => {
    return (
        <>
            <Divider />
            <div className="container">
                <h1 className="my-4 about">Dashboard</h1>
                <button type="button" className="btn btn-md btn-outline-primary">
                    <Link to="/" style={{ color: "black", textDecoration: "none" }}>
                        Back
                    </Link>
                </button>
                <Register flag={0} />
                <div className="wrapper">
                    <Login />
                </div>
            </div>
        </>
    )
}

export default Dashboard;