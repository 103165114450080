import React from 'react'
import '../../assets/styles/photography.css';
import Divider from '../divider'
import Header from '../Navbar';
import { Link } from "react-router-dom";
import Footer from '../Footer';
import SocialMedia from '../Social-media';
import Login from '../Dashboard/Login';

const Photography = () => {
    return (
        <>
            <Divider />
            <Header />
            <div className='container'>
                <h1 className='my-4 about'>D360° Photography Service</h1>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <button type="button" className="btn btn-md btn-outline-primary back-btn" ><Link to='/' style={{ color: "black", textDecoration: "none" }}>Back</Link></button>
                    <Login />
                </div>
                <div className="privacy-flex-container" >
                    <div className='privacy-flex-container-left col-lg-6'>
                        <h2>Outsource/Simplify <span className='blue-text'>Photography</span> and Technology to Us!</h2>
                    </div>
                    <div className='privacy-flex-container-right col-lg-6'>
                        <ul>
                            <li>Top Quality and fastest D360° Photography Service in New York.</li>
                            <li>Same Day Service for upto 700 diamond</li>
                            <li>Instant D360° loading with US based Google Cloud Platform.</li>
                            <li>Ring, Pendent, Earring, Pendent photography</li>
                            <li>Malca-Amit and Brinks window pickup/drop-off </li>
                            <li>Work with all major courier Fedex, UPS, USPS and Ferrari </li>
                            <li>Quality Photography for Blue Nile, Brilliant Earth, Rapnet, VDB, Clean Origin, Green Rock, Fire Diamonds, etc. </li>
                            <li>250k stones handled since 2018 worth more than $1B.</li>
                        </ul>
                    </div>
                </div>

                <div className="privacy-flex-container frame-card" key='1'>
                    <div className='privacy-flex-container-left col-lg-6 justify-content-center'>
                        <iframe title='anish' src="https://d360.tech/compare.html?d=196-B-B2B-58091&btn=0" className="responsive-iframe frame" scrolling="no" id="displayStone" frameBorder="0">
                        </iframe>
                    </div>
                    <div className='privacy-flex-container-right col-lg-6 frame-content'>
                        <ul>
                            <li key='1'>Top Quality and fastest D360° Photography Service in New York.</li>
                            <li key='2'>Same Day Service for upto 700 diamond</li>
                            <li key='3'>Instant D360° loading with US based Google Cloud Platform.</li>
                        </ul>
                    </div>
                </div>

                <div className="privacy-flex-container frame-card" key='2'>
                    <div className='privacy-flex-container-left col-lg-6 justify-content-center'>
                        <iframe title='anish' src="https://d360.tech/view.html?d=ZZ-167-A-6421505808&btn=0" className="responsive-iframe frame" scrolling="no" id="displayStone" frameBorder="0">
                        </iframe>
                    </div>
                    <div className='privacy-flex-container-right col-lg-6 frame-content'>
                        <ul>
                            <li key='1'>Top Quality and fastest D360° Photography Service in New York.</li>
                            <li key='2'>Same Day Service for upto 700 diamond</li>
                            <li key='3'>Instant D360° loading with US based Google Cloud Platform.</li>
                        </ul>
                    </div>
                </div>

                <div className="privacy-flex-container frame-card" key='3'>
                    <div className='privacy-flex-container-left col-lg-6 justify-content-center'>
                        <iframe title='anish' src="https://d360.tech/view.html?d=ZZ-81-C-RING-3&btn=0" className="responsive-iframe frame" scrolling="no" id="displayStone" frameBorder="0">
                        </iframe>
                    </div>
                    <div className='privacy-flex-container-right col-lg-6 frame-content'>
                        <ul>
                            <li key='1'>Top Quality and fastest D360° Photography Service in New York.</li>
                            <li key='2'>Same Day Service for upto 700 diamond</li>
                            <li key='3'>Instant D360° loading with US based Google Cloud Platform.</li>
                        </ul>
                    </div>
                </div>

                <div className="privacy-flex-container frame-card" key='4'>
                    <div className='privacy-flex-container-left col-lg-6 justify-content-center'>
                        <iframe title='anish' src="https://d360.tech/compare.html?d=196-B-B2B-58113&btn=0" className="responsive-iframe frame" scrolling="no" id="displayStone" frameBorder="0">
                        </iframe>
                    </div>
                    <div className='privacy-flex-container-right col-lg-6 frame-content'>
                        <ul>
                            <li key='1'>Top Quality and fastest D360° Photography Service in New York.</li>
                            <li key='2'>Same Day Service for upto 700 diamond</li>
                            <li key='3'>Instant D360° loading with US based Google Cloud Platform.</li>
                        </ul>
                    </div>
                </div>

                <div style={{ marginTop: "50px" }}><SocialMedia /></div>
                <Footer />
            </div >
        </>
    )
}

export default Photography;