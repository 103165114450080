import { setConfig } from "./setProjectConfig";
import { collection, getDocs, query } from "firebase/firestore";
import { firestoreDB } from "./firebase-config";

const setConfigFlag = true;

if (setConfigFlag) {
    await setConfig()
}

let colRef = collection(firestoreDB,"paths") 
let pathDocs = await getDocs(query(colRef))
let paths = {}

for(let pathDoc of pathDocs.docs) {
    paths = { ...paths, ...pathDoc.data() }
}

const BASE_API = paths["base_api"]
export const jsonURL = paths["send_json"].replace("{base_api}", BASE_API)
export const cloudURL = paths["pageview"].replace("{base_api}", BASE_API)
export const refreshURL = paths["userRefresh"].replace("{base_api}", BASE_API)
export const lotURL = paths["lotidview"].replace("{base_api}", BASE_API)
export const searchURL = paths["search"].replace("{base_api}", BASE_API)
export const uploadToBucketURL = paths["upload_to_bucket"].replace("{base_api}", BASE_API)
export const visibilityURL = paths["handleVisibility"].replace("{base_api}", BASE_API)