import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/styles/body.css";
// Navbar Component
const Header = () => {
  const image1 = require("../../assets/images/LOGO.png");

  const handleAnalytics = () => {
    window.open("https://assist.d360.tech/");
  }

  return (
    <header>
      <Navbar variant="light" collapseOnSelect expand="lg" className="justify-content-center p-3">
        <Container>
          <Navbar.Brand href="/" style={{ color: "black", fontWeight: "600" }}>
            <img
              src={image1}
              alt="V360"
              style={{ height: "60px", paddingBottom: "15px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-2">
              
              <Nav.Link as="a" href="https://dashboard.d360.tech/photography" style={{ color: "black", textDecoration: "none" }}>
                <i className="fa-solid fa-camera"></i> Photography
              </Nav.Link>

              <Nav.Link as="a" href="/logistics" style={{ color: "black", textDecoration: "none" }}>
                <i className="fas fa-truck"></i> Logistics
              </Nav.Link>

              <Nav.Link as="a" href="https://assist.d360.tech/" style={{ color: "black", textDecoration: "none" }}>
                <i className="fa-solid fa-magnifying-glass-chart"></i> Analytics
              </Nav.Link>

              <Nav.Link as="a" href="/about" style={{ color: "black", textDecoration: "none" }}>
                <i className="fas fa-user"></i> About
              </Nav.Link>

              <Nav.Link as="a" href="/announcement" style={{ color: "black", textDecoration: "none" }}>
                <i className="fas fa-bullhorn"></i> Announcement
              </Nav.Link>

              <Nav.Link as="a" href="/contact" style={{ color: "black", textDecoration: "none" }}>
                <i className="fa-solid fa-phone"></i> Contact
              </Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;