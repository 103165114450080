import { firestoreDB, config } from "./firebase-config";
import { doc, collection, setDoc, onSnapshot, getDocs, query } from "firebase/firestore";

const getFirestoreDoc = (collectionPath, docID, callback, onChangeCallBack, errorCallBack = null) => {
    if (onChangeCallBack) {
        onSnapshot(query(collection(firestoreDB, collectionPath)), (changes) => {
            const modifiedDocs = changes.docChanges().filter((change) => change.type === "modified" && change.doc.id === docID);
            if (modifiedDocs.length > 0) onChangeCallBack(modifiedDocs[0].doc.data())
        })
    }
    getDocs(query(collection(firestoreDB, collectionPath))).then((snp) => {
        let targetDoc = null
        snp.forEach((doc) => {
            if (doc.id === docID) {
                targetDoc = doc.data()
            }
        })
        if (targetDoc) callback(targetDoc)
        else throw new Error("Doc Not Found")
    }).catch((err) => {
        if (errorCallBack) {
            errorCallBack(err)
        }
    })
}

let projectId = config["projectId"]

let collectiondata = {
    "paths": {
        "assist-functions": {
            "base_api": "https://us-central1-" + projectId + ".cloudfunctions.net",
            "send_json": "{base_api}/send_json",
            "pageview": "{base_api}/pageview",
            "userRefresh": "{base_api}/userRefresh",
            "lotidview": "{base_api}/lotidview",
            "search": "{base_api}/search",
            "upload_to_bucket": "{base_api}/upload_to_bucket",
            "handleVisibility": "{base_api}/handleVisibility"
        }
    }
}

export async function setFirestoreCollections() {
    for (let collectionName in collectiondata) {
        let data = collectiondata[collectionName]
        for (let docName in data) {
            let docData = data[docName]
            await setDoc(doc(firestoreDB, collectionName, docName), docData)
        }
    }
}

export async function setConfig() {
    await setFirestoreCollections()
}