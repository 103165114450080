import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import Divider from "../divider";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Body from "../Body";
import Table from "./table";
import "../../assets/styles/dashboard.css";
import Navigation from "./Navigation";
import Loader from "./loader";

const Page = () => {
    const navigate = useNavigate();
    const [selectedView, setSelectedView] = useState(localStorage.getItem("selectedView") || "page");
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Inbuilt firebase functions to handle user authorization and authentication
        function initFirebaseAuth() {
            onAuthStateChanged(getAuth(), authStateObserver);
        }
        // Handling whether the user is signed in or not
        function authStateObserver(user) {
            if (user) {
                // If signed in and initial value of issignedin is false or null, then set it to true
                if (userInfo === null || userInfo !== user) {
                    setUserInfo(user);
                }
            } else {
                // if signed in is true, and logout action is triggered, then seet it to false
                if (userInfo !== null) {
                    setUserInfo(null);
                }
            }
        }
        initFirebaseAuth();
    }, [navigate, userInfo]);


    const handleSelectView = (view) => {
        setLoading(true);
        setSelectedView(view);
    }

    useEffect(() => {
        setLoading(false);
    }, [selectedView]);

    return (
        <>
            {userInfo !== null ? (<><Divider />
                <Navigation photoURL={userInfo.photoURL} displayName={userInfo.displayName} selectView={handleSelectView} />
                {loading ? <Loader /> : <Table userEmail={userInfo.email} view={selectedView} />}
            </>
            ) : (
                <Body />
            )}

        </>
    )
}

export default Page;