import React from "react";
import "../../assets/styles/dashboard.css";

const Register = ({ flag }) => {
    return (
        <div className="message">
            {flag === 0 ?
                <>
                    To register, contact <span style={{ color: "blue" }}>kalpesh@d360.tech</span>
                    <br></br>
                    If regular user, continue with below Sign-In Option
                </> :
                <>
                    To enable the 360° video dashboard for your company, please contact Kalpesh via email at <span style={{ color: "blue" }}>kalpesh@d360.tech</span> or by phone at <span style={{ color: "blue" }}>+1-646-598-7690</span>
                </>
            }
        </div>
    )
};

export default Register;