import React from 'react'
import '../../assets/styles/footer.css'

const Footer = () => {
  const year = new Date().getFullYear();

  return (
      <>
          <div className='footer'>Copyright &copy; D360 Studio {year}</div>
      </>
  )
}

export default Footer