import './App.css';
import React, { createContext, useState } from 'react';
import Body from './components/Body';
import About from './components/Body/about';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Privacy from './components/Privacy';
import Photography from './components/Features/photography';
import Logistics from './components/Features/logistics';
import Anlytics from './components/Features/analytics';
import Announcement from './components/announcement';
// import Blogs from './components/blogs';
import Form from './components/Dashboard/form';
import Dashboard from './components/Dashboard';
import Contact from './components/Contact';
import Page from './components/Dashboard/page';

export const prefixcontext = createContext();

function App() {
  const [prefixtofetch, setPrefixtofetch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [lotdropdownval, setlotdropdownval] = useState('');

  return (
    // Routes are used for making website multipage 
    <prefixcontext.Provider value={{ prefixtofetch, setPrefixtofetch, currentPage, setCurrentPage, lotdropdownval, setlotdropdownval}}>
      <Router>
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/about" element={<About />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/photography' element={<Photography />} />
          <Route path='/logistics' element={<Logistics />} />
          <Route path='/analytics' element={<Anlytics />} />
          <Route path='/announcement' element={<Announcement />} />
          {/* <Route path='/blogs' element={<Blogs />} /> */}
          <Route path='/contact' element={<Contact />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/dashboard/page' element={<Page />} />
          <Route path='/dashboard/update' element={<Form />} />
        </Routes>
      </Router>
    </prefixcontext.Provider>
  );
}

export default React.memo(App);
