import { initializeApp } from 'firebase/app';
import {
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
} from 'firebase/auth';
import { getFirebaseConfig } from './firebase-config.js';
import logo from "../assets/images/user.png";

async function signIn() {
    var provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    try {
        await signInWithPopup(getAuth(), provider);
    } catch (error) {
        console.error(error);
    }
}

// Signs-out
function signOutUser() {
    sessionStorage.clear();
    signOut(getAuth());
}

// Returns the signed-in user's profile Pic URL.
function getProfilePicUrl() {
    return getAuth().currentUser.photoURL || { logo };
}

// Returns the signed-in user's display name.
function getUserName() {
    return getAuth().currentUser.displayName;
}

function getUserEmail() {
    return getAuth().currentUser.email;
}

// Adds a size to Google Profile pics URLs.
function addSizeToGoogleProfilePic(url) {
    if (url.indexOf('googleusercontent.com') !== -1 && url.indexOf('?') === -1) {
        return url + '?sz=150';
    }
    return url;
}

// Returns true if a user is signed-in.
function isUserSignedIn() {
    return !!getAuth().currentUser;
}

//get firebase authentication and start app
const firebaseAppConfig = getFirebaseConfig();
const app = initializeApp(firebaseAppConfig);
export const auth = getAuth(app);

export { getProfilePicUrl, getUserName, getUserEmail, addSizeToGoogleProfilePic, isUserSignedIn, signIn, signOutUser };