import React from "react";
import { ClipLoader } from "react-spinners";
import Divider from "../divider";
import "../../assets/styles/form.css";

const Loader = ({ flag }) => {
    return (
        <>
            {flag === 0 ?
                <>
                    <Divider />
                    <div className="clip-loader">
                        <ClipLoader />
                        <p style={{marginBottom: "0"}}>&#160;&#160;&#160;Fetching form data...</p>
                    </div>
                </> :
                <>
                    <div className="clip-loader">
                        <ClipLoader />
                        <p style={{marginBottom: "0"}}>&#160;&#160;&#160;Loading...</p>
                    </div>
                </>
            }

        </>
    )
}

export default Loader;